<template>
  <div class="loan-application-loading">
    <Container>
      <Card padding="large" variant="border" class="text-center">
        <Margins v-if="this.getApplication">
          <h1 class="h3 text-normal">
            {{ $t('PLEASE_WAIT_FOR_RENEWAL_REDIRECTION') }}
          </h1>
          <Loader class="margins__double" />
        </Margins>
      </Card>
    </Container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { constants } from '@/mixins';
import { IS_RENEWABLE_ORDER } from '@/types';
import { Card, Container, Loader, Margins } from '@/components';

export default {
  name: 'RenewalApplicationLoading',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Loader,
  },
  props: {
    reference: String,
  },
  mounted() {
    this.getApplication();
  },
  methods: {
    ...mapActions([IS_RENEWABLE_ORDER]),
    async getApplication() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }

      const isRenewable = await this.IS_RENEWABLE_ORDER(this.reference);
      localStorage.setItem('isRenewable', isRenewable);
      localStorage.setItem('reference', this.reference);
      if (isRenewable) {
        this.$router.push({
          name: this.ROUTES.LOAN_APPLICATION.name,
        });
      }
      this.$validator.reset();
    },
  },
};
</script>
